import * as React from "react"
import Layout from "../components/layout"
import logo from "../images/logo.svg"
import { StaticImage } from "gatsby-plugin-image"

export default function IndexPage() {
  return (
    <Layout>
      <div className="flex h-screen">
        <div className="m-auto">
          <div className="flex justify-center items-center m-auto">
            <div class="object-cover w-80 lg:w-[32rem]">
              <StaticImage
                placeholder="tracedSVG"
                loading="eager"
                src="../images/logo.svg"
                alt="logo"
              />
            </div>
          </div>
          <div className="text-center text-base md:text-xl">
            <a href="menu">Meny</a> &middot; <a href="lunch">Lunch</a> &middot;{" "}
            <a href="find-us">Kontakt</a> &middot;{" "}
            <a href="opening-hours">Öppettider</a> &middot;{" "}
            <br />
            <br />
          </div>

          <br />
          <br />
        </div>
      </div>
    </Layout>
  )
}
